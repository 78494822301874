<template>
    <business-sign-up-hero
        title="Para empresas"
        text="Regístra tu empresa obtén tu propia flotilla sin inversión "
    >
        <template v-slot:title>
            <div class="mt-5">
                Para empresas
            </div>
        </template>
        <template v-slot:text>
            Regístra tu empresa<br />
            <span class="z-text-thin">obtén tu propia flotilla sin inversión</span>
        </template>
        <template>
            <div class="z-background-light-grey h-100 p-5 d-flex justify-content-center">
                <div class="clients-section">
                    <p class="z-text-big z-text-normal mt-3">
                        Únete a la red de clientes que disfrutan de Zubut
                    </p>
                    <img
                        class="img-fluid"
                        src="@/assets/img/logos-clientes.png"
                        alt="zubut mensajero"
                        fluid
                    />
                </div>
            </div>
        </template>
    </business-sign-up-hero>
</template>

<script>
import BusinessSignUpHero from "@/components/BusinessSignUpHero";

export default {
    name: "RegisterBusinesss",
    metaInfo: {
        title: "Regístrar tu empresa",
        meta: [
            {
                name: "description",
                content: `Únete a la red de clientes que disfrutan de Zubut.`
            }
        ]
    },
    components: {
        BusinessSignUpHero
    }
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 768px) {
    .clients-section {
        max-width: 400px;
    }
}
</style>
