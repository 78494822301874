<template>
    <b-row>
        <b-col md="6" class="p-0">
            <slot></slot>
        </b-col>
        <b-col class="form-side" md="6" v-bind="$attrs">
            <div class="form-section">
                <h3
                    style="font-size: 12px; font-weight: 600; letter-spacing: 1px;"
                    class="h6 text-uppercase z-text-light text-md-left"
                >
                    <slot name="title">
                        Registra tu empresa
                    </slot>
                </h3>
                <p class="hero-message">
                    <slot name="text">
                        Zubut acompaña a tu empresa en cada parte del camino.
                    </slot>
                </p>
                <business-sign-up />
            </div>
        </b-col>
    </b-row>
</template>

<script>
import BusinessSignUp from "@/components/BusinessSignUp";

export default {
    name: "BusinessSignUpHero",
    components: {
        BusinessSignUp
    }
};
</script>

<style lang="scss" scoped>
.form-side {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-section {
    padding: 1em;

    .hero-message {
        color: #2c2c2c;
        font-family: Montserrat;
        font-size: 24px;
        line-height: 32px;
        text-align: left;
        font-weight: 500;
    }
}

@media only screen and (min-width: 768px) {
    .form-section {
        max-width: 400px;
    }
}
</style>
